import React from 'react'
import Blog from './Blog'
import articles from './db/articles.json'
export default function Blogs() {
  return (
    <div id="blogs" className="blog__area section-padding">
		<div className="container">
			<div className="row">
				<div className="col-xl-12 col-lg-12">
					<div className="blog__area-title section-title">
						<p className="section-top">News</p>
						<h2>Our Blog & Articles</h2> 
					</div>
				</div>
			</div>
			
				
			<div className="row">
			 {
				articles && articles.slice(0,3).map(article => { 
					return <Blog objBlog={article} key={article.id}/>
								
							
				})
			 }
			</div>
			
		</div>
	</div>
  )
}
