import React from 'react'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router'
import projects from './db/projects.json'
import Helmet from 'react-helmet';
export default function ProjectDetails() {
    let {id} = useParams();
    const project = projects.find(project => project.name.toLowerCase() === id.toLowerCase());
  return (
    <div className="project__area__details section-padding">
       <Helmet>
            <title>ZPROXIMA CORP - {project.title}</title>
            <meta name="keyword" content={project.title} />
            <meta name="description" content={project.content.at(0).text} />
        </Helmet> 
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-lg-8 lg-mb-30">
                <div className="project__area__details-left">
                    <div className="project__area__details-left-image">
                        <img src={"/assets/img/"+project.image} alt=""/>
                    </div>
                    <div className="project__area__details-left-content">
                        <h3><a href={project.website} target="_blank">{project.title}</a></h3>
                         
                            {
                                project.content.map((item,index)=>{
                                    return <>{
                                        item.link&&item.link.length>0?
                                        <a href={item.link} target="_blank"><p style={{fontWeight: item.type=="text-bold" ? "bold" :"normal"}}>{item.text}</p></a>
                                        :
                                        <p style={{fontWeight: item.type=="text-bold" ? "bold" :"normal"}}>{item.text}</p>
                                    }</>
                                })
                            }
                        
                        
                        
                    </div>
                    
                </div>
            </div>
            <div className="col-xl-4 col-lg-4">
                <div className="project__area__details-right">
                    <div className="project__area__details-right-item mb-30">
                        <div className="project__area__details-right-item-details">
                            <h3 className="mb-20">Project Details</h3>
                            <ul>
                                <li><i className="far fa-clock"></i><span>Date :</span><a href="#">{project.date}</a></li>
                               
                                <li><i className="fas fa-blog"></i><span>Website :</span><a href={project.website} target="_blank">{project.website}</a></li>
                                <li><i className="far fa-folder-open"></i><span>Category :</span><a href="javascript:void()">{project.category}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="project__area__details-right-item">
                        <div className="project__area__details-right-item-details-help">
                            <h3 className="mb-20">For More information</h3>
                            {/*<p className="mb-20">Saepe optio veritatis dignissimos quae sed aliquid</p>*/}								
                            <div className="project__area__details-right-item-details-help-contact d-inline-flex align-items-center mb-20">
                                <div className="project__area__details-right-item-details-help-contact-icon">
                                    <i className="flaticon-phone-call"></i>
                                </div>
                                <div className="project__area__details-right-item-details-help-contact-text">
                                    <a href="tel:+1-587-401-1270">+1-587-401-1270</a>
                                </div>
                            </div>
                            <div className="project__area__details-right-item-details-help-contact d-inline-flex align-items-center mb-20">
                                <div className="project__area__details-right-item-details-help-contact-icon">
                                    <i className="flaticon-email"></i>
                                </div>
                                <div className="project__area__details-right-item-details-help-contact-text">
                                    <a href="mailto:info@zproxima.com">info@zproxima.com</a>
                                </div>
                            </div>
                            <div className="project__area__details-right-item-details-help-contact d-inline-flex align-items-center">
                                <div className="project__area__details-right-item-details-help-contact-icon">
                                    <i className="flaticon-placeholder"></i>
                                </div>
                                <div className="project__area__details-right-item-details-help-contact-text">
                                    <a href="javascript:void()">166 Ave NW, Edmonton, AB T6V 0H5, Canada</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
