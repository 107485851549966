import Header from './Header';
import Banner from './Banner';
import About from './About';
import Services from './Services';
import Team from './Team';
import Skills from './Skills';
import Testimonial from './Testimonial';
import Blogs from './Blogs';
import Footer from './Footer';
import JqueryEx from './JqueryEx';
import { Route,Switch  } from 'react-router-dom'
import ContactUs from './ContactUs';
import Projects from './Projects';
import Articles from './Articles';
import BlogDetails from './BlogDetails';
import ServiceDetails from './ServiceDetails';
import ScrollToTop from './hooks/ScrollToTop';
import ProjectDetails from './ProjectDetails';
import { useParams,matchPath } from 'react-router';
function App() {
  function blogIdFromPath() {
    const path = '/blog-detail/:id';
    const match = matchPath(document.location.pathname, { path });
    const blogId = match && match.params.id;
  
    return blogId;
  }; 
  const blogId = blogIdFromPath();
  
  return (
    <div >
      
    
      <ScrollToTop />
      {/*<Header />*/}<Header />
       
        <Route  exact path={["/"]} >
            <Banner />
            <About />
            <Services/>
            <Projects/>
            <Team/>
            <Skills/>
            <Testimonial/>
            <Blogs/>  
        </Route>
        <Route  exact path="/contactus" component={ContactUs} />  
        <Route  exact path="/project-detail/:id"  component={ProjectDetails} />
        <Route  exact path="/service-detail/:id" component={ServiceDetails} />
        <Route  exact path="/blog-detail/:id"  component={BlogDetails} />
          
       
        <Route  exact path="/articles" component={Articles} />  
         
       
     
     <Footer/>
     
     {/*<JqueryEx/>*/}<JqueryEx/>
        
      
    </div>
  );
}

export default App;
