import {React,useEffect} from 'react'
import { Link } from 'react-router-dom'

export default function Projects() {
	useEffect(() => {
		 /*========== Active Hover  ==========*/
		/* window.$(".project__area-item").hover(function () {
			window.$(".project__area-item").removeclassName("project__area-item-hover");
			window.$(this).addclassName("project__area-item-hover");
		  });*/
	})
  return (
    <div className="project__area">
		<div className="container-fluid pl-0 pr-0">
			
			<div className="row justify-content-center" >
			<div className="col-xl-12 col-lg-12">
					<div className="blog__area-title section-title">
						<p className="section-top">Projects</p>
						<h2>Our Projects</h2> 
					</div>
				</div>
				<div className="col-xl-3 col-lg-4 col-md-6">
					<div className="project__area-item project__area-item-hover">
						<img src="assets/img/monitoring-zpound.jpg" alt=""/>
						<div className="project__area-overly">
							<h3><Link to="project-detail/0">ZPOUND</Link></h3>
							<p>Inspired to build the future of crypto-investing</p>
							<div className="project__area-icon">
								<Link to="project-detail/zpound"> <i className="flaticon-arrow-right"></i>
								</Link>
							</div>
						</div>
					</div>
				</div>
				
				<div className="col-xl-3 col-lg-4 col-md-6">
					<div className="project__area-item project__area-item-hover">
						<img src="assets/img/monitoring-airsall.jpg" alt=""/>
						<div className="project__area-overly">
							<h3><Link to="project-detail/1">AIRSALL</Link></h3>
							<p>we give you a new online-shopping experience</p>
							<div className="project__area-icon">
								<Link to="project-detail/airsall"> <i className="flaticon-arrow-right"></i>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}
