import React from 'react'
import { Link } from 'react-router-dom'
{/* */}
export default function Banner() {
 
  return (
    
	<div id="home_background" className="banner__area"  data-background="assets/img/banner.jpg" style={{backgroundImage: "url('assets/img/banner.jpg')"}}>
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="banner__area-title">
						
						<h2 className="primary-color" >smart technology for global innovation</h2>
						<span style={{display:"block",color:"black"}} >THE NEW LEVEL IN THE WORLD OF CRYPTO AND FIN-TECH</span>
						<span style={{display:"block",color:"black"}}>build your next buisness with us</span>
						<Link className="theme-btn" to="/contactus">Free Consulting</Link>
					</div>
				</div>
			</div>
		</div>
	</div>
	
  )
}
