import React from 'react'
import { HashRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

export default function Footer() {
  return (
   <div>
        <div className="footer__area pt-100 pb-100" data-background="/assets/img/footer.png">
		<div className="container">
			<div className="row">
				<div className="col-xl-4 col-lg-5 col-md-8 md-mb-30">
					<div className="footer__area-footer-widget">
						<div className="footer__area-logo">
							<HashLink to="/#home">
								<img src="/assets/img/footer-logo.png" alt=""/>
							</HashLink>
						</div>
						<div className="footer__area-content">
							<p>Zproxima Corp. offers services, information and investment opportunities in the crypto and FinTech space.
Do not hesitate to contact us to get a valuable support.</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-lg-3 fo-b col-md-4">
					<div className="footer__area-footer-widget">
						<div className="footer__area-title">
							<h3>Services</h3>
						</div>
						<div className="footer__area-footer-menu">
							<ul>
								<li><Link to="/service-detail/Crypto-investment">- Crypto Investment</Link>
								</li>
								<li><Link to="/service-detail/business strategy">- Business Startegy</Link>
								</li>
								<li><Link to="/service-detail/Fintech service">- Fintech Service</Link>
								</li>
								
							</ul>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-4 col-md-6 md-mb-30">
					<div className="footer__area-footer-widget">
						<div className="footer__area-title">
							<h3>Information</h3>
						</div>
						<div className="footer__area-contact-info">
							<div className="footer__area-contact-info-item d-inline-flex mb-20">
								<div className="footer__area-contact-info-item-icon">
									<i className="fas fa-phone-alt"></i>
								</div>
								<div className="footer__area-contact-info-item-text">
									<a href="tel:+1-587-401-1270">+1-587-401-1270</a>
								</div>
							</div>
							<div className="footer__area-contact-info-item d-inline-flex mb-20">
								<div className="footer__area-contact-info-item-icon">
									<i className="fas fa-envelope"></i>
								</div>
								<div className="footer__area-contact-info-item-text">
								<a href="mailto:info@zproxima.com">info@zproxima.com</a>
								</div>
							</div>
							<div className="footer__area-contact-info-item d-inline-flex">
								<div className="footer__area-contact-info-item-icon">
									<i className="fas fa-map-marker-alt"></i>
								</div>
								<div className="footer__area-contact-info-item-text">
									<a href="javascript:void()">166 Ave NW, Edmonton, AB T6V 0H5, Canada</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-4 col-md-6">
					<div className="footer__area-footer-widget">
						<div className="footer__area-title">
							<h3>Get In Touch</h3>
						</div>
						<form action="#">
							<input type="email" name="email" placeholder="Email" required="required"/>
							<button type="submit" className="theme-banner-btn">submit Now</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
    <div className="footer__area-bottom">
		<div className="container">
			<div className="row align-items-center pt-40 pb-40">
				<div className="col-xl-6 col-lg-6 col-md-7 md-mb-30">
					<div className="footer__area-bottom-left">
						<p>Copyright © 2022 - <HashLink to="/#home">ZPROXIMA CORP.</HashLink> All Right Reserved</p>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-5">
					<div className="footer__area-bottom-right t-right">
						<ul className="d-inline-flex">
							
							<li><a href="https://www.linkedin.com/company/zproxima-corp/" target="_blank"><i className="fab fa-instagram"></i></a>
							</li>
							<li><a href="https://instagram.com/zpound_proxima" target="_blank"><i className="fab fa-linkedin-in mr-0"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
   </div>
  )
}
