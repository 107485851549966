import React from 'react'
import { useEffect,useState }  from 'react'
import Helmet from 'react-helmet';
import Blog from './Blog'
import Pagination from './components/Pagination';
import articles from './db/articles.json'
export default function Articles() {
	const [currentPage, setCurrentPage] = useState(1);
  	const [postsPerPage] = useState(6);
	useEffect	(() => {
		window.$("[data-background]").each(function () {
			window.$(this).css("background-image", "url(" + window.$(this).attr("data-background") + ")")
		  })
	});
	 // Get current posts
	 const indexOfLastPost = currentPage * postsPerPage;
	 const indexOfFirstPost = indexOfLastPost - postsPerPage;
	 const currentPosts = articles.slice(indexOfFirstPost, indexOfLastPost);
   
	 // Change page
	 const paginate = pageNumber => setCurrentPage(pageNumber);
  return (
    <>
    <div className="page__banner" data-background="/assets/img/announcement-3509489_1920.jpg" style={{BackgroundImage: "url('/assets/img/announcement-3509489_1920.jpg')"}}>
		<Helmet>
			<title>ZPROXIMA CORP - Articles</title>
			<meta name="description" content="ZPROXIMA CORP - Articles" />
			<meta keywords="ZPROXIMA CORP - Articles" />	
		</Helmet>
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="page__banner-content">
						<h1>Articles</h1>
						<div className="page__banner-menu">
							<ul>
								<li><a href="#"><i className="flaticon-home-icon-silhouette"></i> Home - </a>
								</li>
								<li> Articles
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div className="blog__area section-padding">
		<div className="container">
			<div className="row">
				{
				currentPosts && currentPosts.map((item,index)=>{
					return <Blog objBlog={item} key={index}/>
				})
				}
			
			</div>		

				 <Pagination postsPerPage={postsPerPage} totalPosts={articles.length} paginate={paginate} currentPage={currentPage}/>
					
					
					
					
			{/*<div className="row">
				<div className="col-lg-12">
					<div className="blog__area-pagination text-center mt-50">
						<ul>
							<li><a href="#"><i className="fas fa-chevron-left"></i></a>
							</li>
							<li><a className="active" href="#">01</a>
							</li>
							<li><a href="#">02</a>
							</li>
							<li><a href="#">03</a>
							</li>
							<li><a href="#"><i className="fas fa-chevron-right"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>*/}
		</div>
	</div>
    </>
  )
}
