import {useEffect,React} from 'react'
import Helmet from 'react-helmet';

export default function ContactUs() {
    useEffect	(() => {
		window.$("[data-background]").each(function () {
			window.$(this).css("background-image", "url(" + window.$(this).attr("data-background") + ")")
		  })
	});
  return (
    <>
        {/* <!-- Page Banner Area Start -->*/}
	<div className="page__banner" data-background="assets/img/contact-us-2993000_1920.jpg" style={{BackgroundImage: "url('/assets/img/contact-us-2993000_1920.jpg')"}}>
		<Helmet>
			<title>ZPROXIMA CORP - Contact Us</title>
		</Helmet>
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="page__banner-content">
						<h1>Contact Us</h1>
						<div className="page__banner-menu">
							<ul>
								<li><a href="#"><i className="flaticon-home-icon-silhouette"></i> Home - </a>
								</li>
								<li> Contact
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	{/* <!-- Page Banner Area End -->*/}    
    {/*<!-- Contact Details Start -->*/}
	<div className="contact__list section-padding">
		<div className="container">
			<div className="row">
				<div className="col-xl-4 col-lg-4 col-md-6 lg-mb-30 wow fadeInUp" data-wow-delay=".4s">
					<div className="contact__list-item">
						<div className="contact__list-item-icon">
							<img src="assets/img/icon/locations.png" alt=""/>
						</div>
						<p>166 Ave NW, Edmonton, AB T6V 0H5, Canada</p>
					</div>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-6 md-mb-30 wow fadeInUp" data-wow-delay=".8s">
					<div className="contact__list-item">
						<div className="contact__list-item-icon">
							<img src="assets/img/icon/phone-call.png" alt=""/>
						</div>
						<a href="tel:+1-587-401-1270">
							<p>+1-587-401-1270</p>
						</a>
						 
					</div>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.2s">
					<div className="contact__list-item">
						<div className="contact__list-item-icon">
							<img src="assets/img/icon/email.png" alt=""/>
						</div>
						 :
						<a href="mailto:info@zproxima.com">
							<p>info@zproxima.com</p>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	{/*<!-- Contact Details End -->*/}
	{/*<!-- Contact Form Start -->*/}
	<div className="contact__form">
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="contact__form t-center">
						<div className="contact__form-form">
							<div className="contact__form-form-title">	<span className="section-top">Get in Touch</span>
								<h2>Always ready for your solutions</h2>
							</div>
							<form action="#">
								<div className="row">
									<div className="col-lg-4 col-md-6 mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-user"></i>
											<input type="text" name="name" placeholder="Your Name" required="required"/>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 md-mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-envelope-open"></i>
											<input type="text" name="email" placeholder="Your Email" required="required"/>
										</div>
									</div>
									<div className="col-lg-4 lg-mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-address-book"></i>
											<input type="text" name="subject" placeholder="Subject" required="required"/>
										</div>
									</div>
									<div className="col-lg-12 mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-comments"></i>
											<textarea name="message" rows="8" placeholder="Message"></textarea>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="contact__form-form-group">
											<button className="theme-btn" type="submit" name="button">Submit Now <i className="flaticon-send"></i>
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	{/*<!-- Contact Form End -->*/}
	{/*<!-- Map Area Start -->*/}
	<div className="contact__map">
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d591.5206713442675!2d-113.55975374101547!3d53.62766471871383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a024e97683a85f%3A0xbe40ad4d856b030c!2s166%20Ave%20NW%2C%20Edmonton%2C%20AB%20T6V%200H5%2C%20Canada!5e0!3m2!1sen!2sae!4v1638985653783!5m2!1sen!2sae" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
	</div>
	{/*<!-- Map Area End --> */}    
    </>
  )
}
