import {useEffect,React} from 'react'
import Helmet from 'react-helmet';
import { useParams } from "react-router";
import services from './db/services.json'
import Service from './Service';
export default function ServiceDetails() {
    let { id } = useParams();
    
    const service = services.find(service => service.title1.toLowerCase() === id.toLowerCase());
    //const otherServices = services.find(service => service.id !== id);
    //const otherServices = services.filter(service => service.id !== id);
    //console.log(service);
    useEffect	(() => {
		window.$("[data-background]").each(function () {
			window.$(this).css("background-image", "url(" + window.$(this).attr("data-background") + ")")
		  })
        
	});
  return (
    <div>
        <Helmet >
            <title>ZPROXIMA CORP - {service.title1}</title>
            <meta name="keyword" content={service.title1} />
            <meta name="description" content={service.content.at(0).text} />
        </Helmet>
    <div className="page__banner" data-background="/assets/img/page-banner.png">
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="page__banner-content">
						<h1 style={{textTransform: "uppercase"}}>{service.title1}</h1>
						<div className="page__banner-menu">
							<ul>
								<li><a href="#"><i className="flaticon-home-icon-silhouette"></i> Home - </a>
								</li>
								<li style={{textTransform: "capitalize"}}> {service.title1}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
    <div className="services__details section-padding">
        <div className="container">
            <div className="row">
                <div className="col-xl-4 col-lg-4 lg-mb-30">
                    <div className="services__details-left-sidebar">
                        <div className="services__details-widget-category mb-30">
                            <h3 className="mb-20" style={{color:"rgb(205, 86, 254)"}}>{service.board.title}</h3>
                             
                               {service.board.content.map((item,index)=>{
                                   return <><p style={{color:"rgb(63,75,163)",fontWeight:"bold"}}>{item.text} </p><br/></>

                               })}
                                
                             
                        </div>
                        <a href="https://zpound.com" target="_blank">
                        <div className="services__details-widget-hours" data-background="/assets/img/z370.jpg" style={{height:250}}>
                            {/*<h3 className="mb-20">Operating Hours</h3>
                            <ul>
                                <li>Sunday - Tuesday<p>7:00 - 4:00</p></li>
                                <li>Thursday<p>11:00 - 8:00</p></li>
                                <li>Friday <p>9:00 - 2:00</p></li>
                                <li>Saturday<p>Closed</p></li>
                            </ul>
                            */}
                            
                        </div>
                        </a>
                    </div>                    
                </div>
                <div className="col-xl-8 col-lg-8">                    
                    <div className="services__details-right-sidebar">
                        <div className="services__details-image mb-35">
                            <img src={"/assets/img/"+service.images.card} alt=""/>
                        </div>                        
                        <div className="services__details-content">
                            <h4 className="mb-25">{service.title2}</h4>
                            {
                                service.content.map((content,index)=>{
                                    return(
                                        <p style={{whiteSpace: "pre-line"}}>
                                            {content.text}
                                       
                                        </p>
                                        
                                    )
                                }
                            )}
                            
                            <div className="services__details-services">
                                <h4 className="mb-30 pt-30">Releted Services</h4>
                                <div className="row">
                                      {
                                          services&&services.filter(service => service.title1.toLowerCase() !== id.toLowerCase()).map((service,index)=>{
                                                return(
                                                    <Service objService={service}/>
                                                )
                                          })
                                      }   
                                        {/*
                                            otherServices&&otherServices.forEach((otherService,index)=>{
                                                
                                                //return <Service objService={otherService}/>
                                                  <div>otherService.id</div>
                                            })
                                        */}                           
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
