import React from 'react'
import Helmet from 'react-helmet';
import { useParams } from "react-router";
import { Link,useLocation } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
import articles from './db/articles.json'
export default function BlogDetails() {
    let { id } = useParams();
    let location = useLocation();
    //console.log(id);
    
    const article = articles.find(article => article.title.toLowerCase() === id.toLowerCase());
    const previousArticle = article && articles.find(previousArticle => previousArticle.id === article.id-1);
    const nextArticle = article && articles.find(nextArticle => nextArticle.id === article.id+1);
    //alert(nextArticle.id + "," + article.id + "," + previousArticle.id);
    //document.title = article.title;
  return (
    <div className="blog__area-single section-padding">
		<div className="container">
			<div className="row">
				<div className="col-xl-8 col-lg-8 lg-mb-30">
					<div className="blog__details-left">	
                    {
                        article ? <>
                            <Helmet>
                        <title>ZPROXIMA CORP - {article.title}</title>
                        <meta name="keyword" content={article.title} />
                        <meta name="description" content={article.content.at(0).text} />
                        <meta name="image" content={window.location.origin+"/assets/img/blog/"+ article.images.x1480} />

                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:creator" content={"ZPROXIMA CORP"} />
                        <meta name="twitter:title" content={article.title} />
                        <meta name="twitter:image" content={window.location.origin+"/assets/img/blog/"+ article.images.x1480} />
                        <meta name="twitter:description" content={article.content.at(0).text} />
                    </Helmet>			
						<div className="blog__details-left-image">
							<img src={"/assets/img/blog/"+article.images.x3080} alt=""/>
						</div>
						<div className="blog__details-left-content mb-40">
                            <div className="blog__details-left-content-meta mb-20">
								<ul>
									<li><a href="#"><i className="far fa-user"></i>By - Admin</a></li>
									<li><a href="#"><i className="flaticon-calendar"></i>{article.date}</a></li>
									{/*<li><a href="#"><i className="far fa-comment-dots"></i>3 Comment</a></li>*/}
								</ul>
                            </div>
							<h3 className="mb-15" style={{textTransform: 'uppercase'}}>{article.title}</h3>
							
							{
                                article&&article.content.map((item,index) => {
                                    return <><p className="mb-30" style={{fontWeight:item.type=="text-bold"?"bold":"normal",whiteSpace: "pre-line"}} key={index}>{item.text}</p></>
                                    
                                })
                            }
						</div>
                        <div className="blog__details-left-related">
                            <div className="row">
                                
                                <div className="col-md-10 t-right md-t-left">
                                    <div className="blog__details-left-related-share">
                                       {/* <p>Share:</p>
                                        <FacebookShareButton url={window.location.href} title={article.title} hashtag={"#zpound,#airsall,#investment,#crypto-currency"}>
                                            <FacebookIcon round size={32} />
                        </FacebookShareButton>
                                        <TwitterShareButton
                                            title={article.title}
                                            url={window.location.href}
                                            hashtags={["zproxima", "zpound", "airsall", "investment", "crypto-currency"]}
                                        >
                                            <TwitterIcon size={32} round />
                                           
                                        </TwitterShareButton>
                                        <ul>
											<li><a href="#"><i className="fab fa-facebook-f"></i></a>
											</li>
											<li><a href="#"><i className="fab fa-twitter"></i></a>
											</li>
											<li><a href="#"><i className="fab fa-linkedin-in"></i></a>
											</li>
                                            <li><a href="#"><i class="fab fa-quora"></i></a>
											</li>
                                            <li><a href="#"><i class="fab fa-instagram"></i></a>
											</li>
                                            <li><a href="#"><i class="fab fa-telegram"></i></a>
											</li>
                                            <li><a href="#"><i class="fab fa-whatsapp"></i></a>
											</li>
                                            <li><a href="#"><i class="fab fa-reddit"></i></a>
											</li>
                                            <li><a href="#"><i class="fab fa-discord"></i></a>
											</li>
                                            <li><a href="#"><i class="fab fa-pinterest"></i></a>
											</li>
                                             

                                            
										</ul>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className="blog__details-left-post mb-40">
                            {
                               
                            }
							<div className="row mb-45">
								<div className="col-sm-6">
									<div className="blog__details-left-post-item sm-mb-30">
										<div className="blog__details-left-post-item-icon">
											{
                                                previousArticle?<Link to={previousArticle?"/blog-detail/"+previousArticle.title:""}><i className="flaticon-left-arrow mr-10"></i>Previous</Link>:""
                                            }
										</div>
                                        <div className="all__sidebar-item-post-item-thumb mr-15 float-left">
                                            <Link to={previousArticle?"/blog-detail/"+previousArticle.title:""} ><img src={previousArticle?"/assets/img/blog/"+previousArticle.images.x320:""} alt=""/></Link>
                                        </div>
										<p>{previousArticle ?previousArticle.title:""}</p>
									</div>
								</div>
								<div className="col-sm-6 t-right">
									<div className="blog__details-left-post-item">
										<div className="blog__details-left-post-item-icon">
                                        {
                                            nextArticle?<Link to={nextArticle?"/blog-detail/"+nextArticle.title:""}>Next<i className="flaticon-right-arrow ml-10"></i></Link>:""
                                        }
										</div>
                                        <div className="all__sidebar-item-post-item-thumb mr-15 float-right" >
                                            <Link to={nextArticle?"/blog-detail/"+nextArticle.title:""} ><img src={nextArticle?"/assets/img/blog/"+nextArticle.images.x320:""} alt=""/></Link>
                                        </div>
										<p className="text-align-left" style={{textAlign:"left"}}>{nextArticle ? nextArticle.title : ""}</p>
									</div>
								</div>
							</div>
							
						</div>
                        </>:<div className="justify-content-center">
                            <p style={{fontWeight:"bold"}}>Page Not Found</p>
                            <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                            <div style={{marginTop:20}}>
                                    <Link to="/" style={{textDecorationLine:"underline"}}>Go to Homepage</Link> - <Link to="/Articles" style={{textDecorationLine:"underline"}}>Go to Blog Page </Link>

                            </div>
                            
                        </div>
                    }
						
												
                    </div>						
				</div>
                <div className="col-xl-4 col-lg-4">
                    <div className="all__sidebar">
                        
                        <div className="all__sidebar-item mb-30">
                            <h3 className="mb-30">Recent Post</h3>
                            <div className="all__sidebar-item-post">
                            {
                                articles && articles.filter(item => item.title !== id).slice(0,3).map((item,index) => {
                                  
                                    return (
                                    <div >
                                        <div className="all__sidebar-item-post-item mb-20">
                                        <div className="all__sidebar-item-post-item-thumb mr-15">
                                            <Link to={"/blog-detail/"+item.title} key={index}><img src={"/assets/img/blog/"+item.images.x320} alt=""/></Link>
                                        </div>
                                        <div className="all__sidebar-item-post-item-content">
                                            <h6><Link to={"/blog-detail/"+item.title} key={index}>{item.title}</Link></h6>
                                            <span><i className="flaticon-calendar"></i><a href="javascript:void()" key={index}>{item.date}</a></span>
                                        </div>
                                        </div>
                                    </div>)
                                })}
                                 
                            </div>
                        </div>
                       
                       
                        {/*<div className="all__sidebar-item">
                            <h3 className="mb-30">Our Gallery</h3>
                            <div className="all__sidebar-item-gallery">
                                <div className="row">
                                    <div className="col-6 pr-10 mb-20">
                                        <div className="all__sidebar-item-gallery-item">
                                            <a className="img-popup" href="assets/img/blog-3.jpg">
                                                <img src="/assets/img/blog-3.jpg" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-6 pl-10">
                                        <div className="all__sidebar-item-gallery-item">
                                            <a className="img-popup" href="/assets/img/blog-2.jpg">
                                                <img src="/assets/img/blog-2.jpg" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-6 pr-10">
                                        <div className="all__sidebar-item-gallery-item">
                                            <a className="img-popup" href="/assets/img/blog-4.jpg">
                                                <img src="/assets/img/blog-4.jpg" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-6 pl-10">
                                        <div className="all__sidebar-item-gallery-item">
                                            <a className="img-popup" href="/assets/img/blog-1.jpg">
                                                <img src="/assets/img/blog-1.jpg" alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
			</div>
		</div>
	</div>
  )
}
