import {useEffect,React} from 'react'

export default function Testimonial() {
    useEffect	(() => {
        // /*==========  testimonial  ==========*/
    var swiper = new window.Swiper(".testimonial-slider", {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 100,
        pagination: {
          el: ".dots",
          clickable: true,
        },
        breakpoints: {
          991: {
            spaceBetween: 100,
          },
        }
      });
    })
  return (
    <div className=" section-padding">
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="swiper testimonial__area-bg testimonial-slider p-relative">
                    <div className="swiper-wrapper testimonial__area-group">
                        {/*<div className="swiper-slide testimonial__area-item">
                            <div className="row align-items-center">
                                <div className="col-sm-3 md-mb-30">
                                    <div className="testimonial__area-image p-relative">
                                        <img src="assets/img/testmonials-1.jpg" alt=""/>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="testimonial__area-content p-relative">
                                        <p className="mb-15">Our objective is not to predict the future, but to help you build it in a sustainable way</p>
                                        <h5>Osama Aly</h5>
                                        <p>Chairman</p> <i className="flaticon-right-quotes-symbol"></i>
                                    </div>
                                </div>
                            </div>
  </div>*/}
                        <div className="swiper-slide testimonial__area-item">
                            <div className="row align-items-center">
                                <div className="col-sm-3 md-mb-30">
                                    <div className="testimonial__area-image p-relative">
                                        <img src="assets/img/testmonials-2.jpg" alt=""/>
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <div className="testimonial__area-content p-relative">
                                        <p className="mb-15">We at Zproxima have always believed that great things happen in our world with passion and also through the right source of inspiration and advice.</p>
                                        <h5>Ahmed Attya</h5>
                                        <p>CEO</p> <i className="flaticon-right-quotes-symbol"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="pagination-area text-right">
                        <div className="dots"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
