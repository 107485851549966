import {React} from 'react'
import { Link } from 'react-router-dom'

export default function Services() {

  return (
    <div id="services" className="services__area section-padding" data-background="assets/img/shape.png">
		<div className="container">
			<div className="row">
				<div className="col-xl-12 col-lg-12">
					<div className="services__area-title section-title">
						<p className="section-top">Our Services</p>
						<h2>We offer the best Services for progress!</h2> 
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xl-4 col-lg-4 col-md-6 lg-mb-30">
					<div className="services__area-item">
						<div className="services__area-item-icon">
							<img src="assets/img/icon/services-1.png" alt=""/>
						</div>
						<h3><Link to="/service-detail/Crypto-investment">Crypto Investment</Link></h3>
						<p>Today, Web 3.0, or the Internet of value, is about to revolutionise it.</p> <Link to="/service-detail/Crypto-investment">Read More</Link>
					</div>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-6 md-mb-30">
					<div className="services__area-item">
						<div className="services__area-item-icon">
							<img src="assets/img/icon/services-2.png" alt=""/>
						</div>
						<h3><Link to="/service-detail/business strategy">Business Strategy</Link></h3>
						<p>How to move forward at full speed in the face of</p> <Link to="/service-detail/business strategy">Read More</Link>
					</div>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-6">
					<div className="services__area-item">
						<div className="services__area-item-icon">
							<img src="assets/img/icon/services-3.png" alt=""/>
						</div>
						<h3><Link to="/service-detail/Fintech service">Fintech</Link></h3>
						<p>Investment in fintech has never been higher.</p> <Link to="/service-detail/Fintech service">Read More</Link>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}
