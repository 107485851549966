import React from 'react'
import {Link} from 'react-router-dom'
export default function Service({objService}) {
  return (
    <div className="col-xl-6 col-lg-6 col-md-6 md-mb-30">
					<div className="services__area-item">
						<div className="services__area-item-icon">
							<img src={"/assets/img/icon/"+objService.images.icon} alt=""/>
						</div>
						<h3><Link to={"/service-detail/"+objService.title1}>{objService.title1}</Link></h3>
						<p>{objService.content[0].text.slice(0,60)}</p> <Link to={"/service-detail/"+objService.title1}>Read More</Link>
					</div>
				</div>
  )
}
