import {useEffect,React} from 'react'
import {HashLink} from 'react-router-hash-link'
export default function Skills() { 
	
	useEffect	(() => {
		window.$("[data-background]").each(function () {
			window.$(this).css("background-image", "url(" + window.$(this).attr("data-background") + ")")
		  })
		  window.$('.video-popup').magnificPopup({
			type: 'iframe'
		  });
	});
  return (
    <div className="skill__area section-padding pb-0" data-background="assets/img/skill.jpg" style={{BackgroundImage: "url('assets/img/skill.jpg')"}}>
		<div className="container">
			<div className="row align-items-center">
				<div className="col-xl-8 col-lg-8 col-md-9 lg-mb-40">
					<div className="skill__area-title">
						<h2 className="mb-35">You will position yourself better for opportunities in this space</h2>
						<HashLink className="theme-banner-btn" to="/#services">Discover More</HashLink>
					</div>
				</div>
				<div className="col-xl-4 col-lg-4 col-md-3">
					<div className="skill__area-play"> <a className="skill__area-video-icon video-popup" href="https://www.youtube.com/watch?v=giRNnV4RqsY"><span></span></a>
					</div>
				</div>
			</div>
			
		</div>
	</div>
  )
}
