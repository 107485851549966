import React from 'react';
import ScrollToTop from '../hooks/ScrollToTop';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="row">
        
        <div className="col-lg-12">
            <div className="blog__area-pagination text-center mt-50">
                <ul>
                    {pageNumbers.map(number => (
                    <li key={number} >
                        <a onClick={() => {paginate(number);window.scrollTo(0, 0);}} href="javascript:void(); " className={number===currentPage ? "active":""}>
                        {number}
                        
                        </a>
                    </li>
            ))}
                </ul>
            </div>
        </div>
    </div>
     
  );
};

export default Pagination;

	{/*<div className="row">
				<div className="col-lg-12">
					<div className="blog__area-pagination text-center mt-50">
						<ul>
							<li><a href="#"><i className="fas fa-chevron-left"></i></a>
							</li>
							<li><a className="active" href="#">01</a>
							</li>
							<li><a href="#">02</a>
							</li>
							<li><a href="#">03</a>
							</li>
							<li><a href="#"><i className="fas fa-chevron-right"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>*/}