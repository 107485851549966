import {useEffect,React} from 'react'
import Helmet from 'react-helmet';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
{/* */}
export default function Header() {
	const history = useHistory();
	 
	useEffect(() => {
		  // /*========== Nice Select ==========*/
		 window.$('select').niceSelect();
		new window.WOW().init();	
			
	})
	
	window.hrefToLink=(href)=>{
		
		history.push(href);
		if(href.includes('/#'))window.location.href = href;
		/*window.jQuery('.mean-nav ul > li > a:first-child').on( "click" , function () {
			window.jQuery('.mean-nav ul:first').slideUp();
			window.menuOn = false;
			window.jQuery(window.$navreveal).toggleClass("meanclose").html(window.meanMenuOpen);
		});*/
	}
	
  return (
   <div id="home">
	   <Helmet>
		   <title>ZPROXIMA CORP - HOME</title>
	   </Helmet>
	    <div className="header__area">
		<div className="header__area-topbar-area">
			<div className="header__area-topbar custom-container">
				<div className="header__area-topbar-left">
					<div className="header__area-topbar-left-info md-mb-10">
						<ul>
							<li><a href="#"><i className="flaticon-placeholder"></i>AB T6V 0H5, Canada</a>
							</li>
							<li><a href="#"><i className="flaticon-email"></i>info.@zproxima.com</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="header__area-topbar-right">
					<div className="header__area-topbar-right-language">
						<select name="select">
							<option value="1">English</option>
							
						</select>
					</div>
					<div className="header__area-topbar-right-social">
						<ul>
							
							<li><a href="https://instagram.com/zpound_proxima" target="_blank"><i className="fab fa-instagram"></i></a>
							</li>
							<li><a href="https://www.linkedin.com/company/zproxima-corp/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
        {/* <!-- TopBar Area Start -->*/}
		{/*<!-- Menu Bar Area Start --> */}
        
		
		<div style={{background:"black"}}>
		<div className="header__area-menubar-area">
			<div id="topx" className="header__area-menubar custom-container">
				<div className="header__area-menubar-logo">
					
					<div className="row">
					<div>
						<HashLink to="/#home">
						
						<img src="/assets/img/logo.png" alt=""/>
						
					</HashLink>
					</div>
					<div>
					
					<Link className="buyZpound" to="//zpound.com" target="_blank" style={{}}><img src="/assets/img/buyZpound.jpg" alt=""/></Link>
					</div>
					</div>
					<div className="responsive-menu"></div>					
				</div>
				<div className="header__area-menubar-main-menu">
					<ul id="mobilemenu">
						<li ><HashLink to="/#home">Home</HashLink></li>
						<li className="header__area-menubar-main-menu-icon"><a href="javascript:void()">Services</a>
							<ul className="sub-menu">
								<li><Link to="/service-detail/Crypto-investment" >Crypto Investment</Link>
								</li>
								<li><Link to="/service-detail/business strategy">Business Strategy</Link>
								</li>
								<li><Link to="/service-detail/Fintech service">Fintech</Link>
								</li>
							</ul>
						</li>
						<li className="header__area-menubar-main-menu-icon"><a href="javascript:void()">Projects</a>
							<ul className="sub-menu">
								<li><Link to="/project-detail/zpound"> Zpound</Link></li>
								<li><Link to="/project-detail/airsall"> Airsall</Link>
								</li>
								 
							</ul>
						</li>
						<li className="header__area-menubar-main-menu-icon"><a href="javascript:void()">News</a>
							<ul className="sub-menu">
								<li><HashLink to="/#blogs">Last News</HashLink>
								</li>
								<li><Link to="/articles">Articles</Link>
								</li>
								 
							</ul>
						</li>
						<li><Link to="/contactus">Contact Us</Link></li>		
					</ul>
				</div>
				<div className="header__area-menubar-right">
					{/*<div className="header__area-menubar-right-search">
						<div className="search">	
							<span className="header__area-menubar-right-search-icon open"><i className="flaticon-search"></i></span>
						</div>
						<div className="header__area-menubar-right-search-box">
							<form>
								<input type="search" placeholder="Search Here....."/>
								<button type="submit"><i className="flaticon-search"></i>
								</button>
							</form> <span className="header__area-menubar-right-search-box-icon"><i className="flaticon-close"></i></span>
						</div>
						</div>
					<div className="header__area-menubar-right-hamburger">
						<div className="hamburger-icon"> 
							<i className="fas fa-bars"></i>
						</div>
					</div>*/}
					<div className="header__area-menubar-right-btn">
						<a className="theme-btn " href="https://zpound.com" target="_blank" style={{color: 'gold',}}>BUY ZPOUND</a>
					</div>
				</div>
			</div>
			{/*<!-- Hamburger Menu Start -->*/}
			<div className="hamburger-popup">
				<div className="hamburger-close-btn">
					<img src="assets/img/close.png" alt=""/>
				</div>
				
			</div>
			<div className="hamburger-overlay"></div>
			
            {/* <!-- Hamburger Menu Start -->*/}
		</div>
		</div>
        
		{/*<!-- Menu Bar Area Start --> */}
	</div>
	<div id="price_list">
			<div id="coinmarketcap-widget-marquee" coins="1,1027,1839,825,2010,52,8916,6636,2,512,3890,5994,2502,7083,638,1958" currency="USD"  theme="dark" transparent="false" show-symbol-logo="true"></div>
		</div>
   </div>
  )
}
