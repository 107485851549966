import React from 'react'
import {Link} from 'react-router-dom'
export default function Blog({objBlog}) {
  return (
    <div className="col-xl-4 col-lg-6 xl-mb-30 mb-30" >
        <div className="blog__area-item">
            <div className="blog__area-image">
                <img src={"/assets/img/blog/"+objBlog.images.x1480} alt=""/>
                <div className="blog__area-date">
                    <h3>{objBlog.date.slice(0,2)}</h3>
                    <p>{objBlog.date.slice(3,objBlog.date.length)}</p>
                </div>
            </div>
            <div className="blog__area-content">
                {/*<ul>
                    <li><i className="flaticon-user"></i> Admin</li>
                    <li><i className="flaticon-bubble-chat"></i> 3 Comment</li>
                </ul>*/}
                <h3><Link to={"/blog-detail/"+objBlog.title} >{objBlog.title }</Link></h3>
                <p>{objBlog.content[0].text.length>75 ?objBlog.content[0].text.slice(0,75):objBlog.content[0].text }...</p> <Link to={"/blog-detail/"+objBlog.title}>Read More</Link>
            </div>
        </div>
	</div>
  )
}
