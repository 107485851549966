import React, { Component } from 'react'

export class JqueryEx extends Component {
  componentDidMount() {
      // /*========== Nice Select ==========*/
		//window.$('select').niceSelect();
    // /*==========  wow  ==========*/
    //new window.WOW().init();		
    // /*========== Responsive Menu  ==========*/
    window.$('.header__area-menubar-main-menu').meanmenu({
      meanMenuContainer: '.responsive-menu',
      meanScreenWidth: '991',
      //meanMenuOpen: '<span></span><span></span><span></span>',
      meanMenuOpen: '<span></span><span></span><span></span>',
      meanMenuClose: '<i class="flaticon-close"></i>'
    });
    /*========== menu-bar sticky  ==========*/
    /*window.$(window).on('scroll', function () {
      var scrollDown = window.$(window).scrollTop();
      if (scrollDown < 135) {
        window.$(".header__area-menubar-area").removeClass("header__area-menubar-area-sticky-menu");
      } else {
        window.$(".header__area-menubar-area").addClass("header__area-menubar-area-sticky-menu");
      }
    });*/
    /*==========  counterUp  ==========*/
    /*var counter = window.$('.counter');
    counter.counterUp({
      time: 2500,
      delay: 100
    });*/
    /*==========  Search  =========*/
    /*window.$('.header__area-menubar-right-search-icon.open').on('click', function () {
      window.$('.header__area-menubar-right-search-box').fadeIn().addClass('active');
    });
    window.$('.header__area-menubar-right-search-box-icon').on('click', function () {
      window.$(this).fadeIn().removeClass('active');
    });
    window.$('.header__area-menubar-right-search-box-icon i').on('click', function () {
      window.$('.header__area-menubar-right-search-box').fadeOut().removeClass('active');
    });
    window.$('.header__area-menubar-right-search-box form').on('click', function (e) {
      e.stopPropagation();
    });*/
    // /*==========  sidebar popup  ==========*/
    /*window.$('.hamburger-icon i').on("click", function () {
      window.$('.hamburger-popup').addClass('active');
    });
    window.$('.hamburger-popup .hamburger-close-btn').on("click", function () {
      window.$('.hamburger-popup').removeClass('active');
    });
    window.$('.hamburger-icon i').on("click", function () {
      window.$('.hamburger-overlay').addClass('show');
    });
    window.$('.hamburger-popup .hamburger-close-btn').on("click", function () {
      window.$('.hamburger-overlay').removeClass('show');
    });*/
    /*==========  img-popup  ==========*/
    /*window.$('.img-popup').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      }
    });*/
    /*==========  video-popup  ==========*/
    /*window.$('.video-popup').magnificPopup({
      type: 'iframe'
    });*/
    /*==========  background  ==========*/
    /*window.$("[data-background]").each(function () {
      window.$(this).css("background-image", "url(" + window.$(this).attr("data-background") + ")")
    })*/
    // /*==========  testimonial  ==========*/
    /*var swiper = new window.Swiper(".testimonial-slider", {
      slidesPerView: 1,
      loop: true,
      spaceBetween: 100,
      pagination: {
        el: ".dots",
        clickable: true,
      },
      breakpoints: {
        991: {
          spaceBetween: 100,
        },
      }
    });*/
    /*========== scroll to top  ==========*/
    /*window.$(window).on('scroll', function () {
      if (window.$(this).scrollTop() > 200) {
        window.$('.scroll-top').fadeIn(200);
      } else {
        window.$('.scroll-top').fadeOut(200);
      }
    });
    window.$('.scroll-top').on('click', function (event) {
      event.preventDefault();
      window.$('html, body').animate({
        scrollTop: 0
      }, 1000);
    });*/
    /*==========  theme loader  ==========*/
    window.$(window).on("load", function () {
      /*window.$(".theme-loader").fadeOut(500);
    
    if(window.$('.skilbar__area-bar').length) {
      window.$('.skilbar__area-bar').appear(function(){
        var el = window.$(this);
        var percent = el.data('width');
        window.$(el).css('width', percent+'%');
      }, {accY: 0});
      };*/
      /*========== FAQ  ==========*/
     /* window.$(".faq__area-collapse-item-card-header").click(function () {
        if (window.$(this).next(".faq__area-collapse-item-card-header-content").hasClass("active")) {
          window.$(this).next(".faq__area-collapse-item-card-header-content").removeClass("active").slideUp()
          window.$(this).children("i").removeClass("flaticon-minus").addClass("flaticon-plus")
        } else {
          window.$(".faq__area-collapse-item-card .faq__area-collapse-item-card-header-content").removeClass("active").slideUp()
          window.$(".faq__area-collapse-item-card .faq__area-collapse-item-card-header i").removeClass("flaticon-minus").addClass("flaticon-plus");
          window.$(this).next(".faq__area-collapse-item-card-header-content").addClass("active").slideDown()
          window.$(this).children("i").removeClass("flaticon-plus").addClass("flaticon-minus")
        }
      });*/
    /*========== Active Hover  ==========*/
    window.$(".project__area-item").hover(function () {
      window.$(".project__area-item").removeClass("project__area-item-hover");
      window.$(this).addClass("project__area-item-hover");
    });
    /*========== Active Hover  ==========*/
    window.$(".choose__ua-right-list-icon").hover(function () {
      window.$(".choose__ua-right-list-icon").removeClass("choose__ua-right-list-icon-hover");
      window.$(this).addClass("choose__ua-right-list-icon-hover");
    });
  });
  //if(document.getElementById('home_background')) window.movingBoxes.init();
  }
  render() {
    return (
      <></>
    )
  }
}

export default JqueryEx