import React from 'react'

export default function Team() {
  return (
    <div className="team__area section-padding">
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="services__area-title section-title">
                    <p className="section-top">Our Team</p>
                    {/*<h2>Our Expert Team Member</h2> */}
                </div>
            </div>
        </div>
        <div className="row justify-content-center"  >
           {/* <div className="col-xl-3 col-lg-3 col-md-6 lg-mb-30">
                <div className="team__area-item">
                    <div className="team__area-image p-relative">
                        <div className="team__area-image-img">
                            <img src="assets/img/team-1.jpg" alt=""/>
                        </div>
                        <div className="team__area-icon">
                            <ul>
                                <li><a href="https://www.linkedin.com/company/zproxima-corp/" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                                
                               
                            </ul>
                        </div>
                    </div>
                    <div className="team__area-content">
                        <h3>Osama Aly</h3>
                        <p>Chairman</p>
                    </div>
                </div>
  </div>*/}
            <div className="col-xl-3 col-lg-3 col-md-6 md-mb-30">
                <div className="team__area-item">
                    <div className="team__area-image p-relative">
                        <div className="team__area-image-img">
                            <img src="assets/img/team-2.jpg" alt=""/>
                        </div>
                        <div className="team__area-icon">
                            <ul>
                            <li><a href="https://www.linkedin.com/in/ahmed-attia-05623518a" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="team__area-content">
                        <h3>Ahmed Attya</h3>
                        <p>CEO</p>
                    </div>
                </div>
            </div>
            {/*<div className="col-xl-3 col-lg-3 col-md-6 md-mb-30">
                <div className="team__area-item">
                    <div className="team__area-image p-relative">
                        <div className="team__area-image-img">
                            <img src="assets/img/team-3.jpg" alt=""/>
                        </div>
                        <div className="team__area-icon">
                            <ul>
                            <li><a href="https://www.linkedin.com/in/hassan-eladawi-27702732" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="team__area-content">
                        <h3>Hassan Eladawi</h3>
                        <p>CTO</p>
                    </div>
                </div>
            </div>*/}
            <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="team__area-item">
                    <div className="team__area-image p-relative">
                        <div className="team__area-image-img">
                            <img src="assets/img/team-4.jpg" alt=""/>
                        </div>
                        <div className="team__area-icon">
                            <ul>
                            <li><a href="https://www.linkedin.com/in/hicham-b-khalifi-3a7b45149" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="team__area-content">
                        <h3>Hicham khalifi</h3>
                        <p>CFO</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
