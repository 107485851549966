import React from 'react'
import { HashLink } from 'react-router-hash-link'

export default function About() {
  return (
    <div className="about__area section-padding">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 lg-mb-30">
                <div className="about__area-left">
                    {/*<div className="about__area-left-content">
                        <h6><span className="counter">25</span>+</h6>
                        <p>Years Experience</p>
                    </div>*/}
                    <div className="about__area-left-image">
                        <img src="assets/img/about.jpg" alt=""/>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6">
                <div className="about__area-right">
                    <div className="about__area-right-title">
                        <p className="section-top">About Company</p>
                        <h2>ZPROXIMA CORP. <div style={{textTransform:"none"}}>Concept of an identity</div></h2>
                        <p>
                        ZPROXIMA CORP. is your source for international business consulting that partners with experts in the digital and FinTech world. Our biggest challenge is to capture the biggest opportunities for you. Our success depends on a spirit of deep collaboration and a special community of diverse people committed to making the world a better place every day.
                            Today, we aim to work closely with clients to take a transformational approach to benefit all stakeholders - enabling startups to grow, create sustainable competitive advantage and make a positive impact. Our diverse and international experts are passionate about unlocking the potential and realising change at the FinTech and crypto-investment level, and provide integrated solutions through cutting-edge management consulting, technology and design, as well as business and digital projects.
                        </p>
                    </div>
                   
                    <div className="about__area-right-contact">
                        <div className="about__area-right-contact-item">
                            <HashLink className="theme-btn" to="/#services">Discover More</HashLink>								
                        </div>	
                        				
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
